import { Injectable } from '@angular/core';
import { EProviderType } from '../model/eprovider-type';
import { MSALProviderService } from './msal-provider.service';

import { User } from '../model/user';
import { Observable, Subject, Subscription } from 'rxjs';
import { IAuthenticationProvider } from './iauthentication-provider';
import { AnonymousProviderService } from './anonymous-provider.service';
import { ClientConfiguration } from '../../model/client-configuraton';
import { AccessToken } from '../model/access-token';

@Injectable()
export class AuthenticationService {

   private readonly SESSION_KEY_PROVIDER_TYPE = 'asc.authentication.provider-type';
   private readonly SESSION_KEY_REDIRECT_URL = 'asc.authentication.redirect-url';

   private providerType?: EProviderType;
   private provider!: IAuthenticationProvider;
   private subscription = new Subscription();
   private isAuthenticatedSubject = new Subject<boolean>();
   private isLoggingInSubject = new Subject<void>();
   private clientConfiguration!: ClientConfiguration;

   constructor(
      private msalProviderService: MSALProviderService
   ) { }

   /**
    * Checks for valid authentication token and tries loading the User profile.
    */
   public async initializeAsync(clientConfiguration: ClientConfiguration): Promise<void> {
      this.clientConfiguration = clientConfiguration;

      // Restore authentication provider
      const cachedProviderType = sessionStorage.getItem(this.SESSION_KEY_PROVIDER_TYPE) || undefined;
      const providerType: EProviderType = (cachedProviderType) ? (<any>EProviderType)[cachedProviderType] : undefined;
      this.providerType = providerType || EProviderType.Anonymous;
      await this.setAuthenticationProviderAsync(this.clientConfiguration?.authentication.provider || EProviderType.Anonymous);

      this.checkIsAuthenticated();
   }

   public checkIsAuthenticated() {
      if (this.isAuthenticated) {
         this.isAuthenticatedSubject.next(this.isAuthenticated);
      }
   }

   public getRedirectUrl(): string | undefined {
      const redirectUrl = sessionStorage.getItem(this.SESSION_KEY_REDIRECT_URL) || undefined;

      // Clear value
      sessionStorage.removeItem(this.SESSION_KEY_REDIRECT_URL);

      return redirectUrl;
   }

   public setRedirectUrl(redirectUrl: string) {
      sessionStorage.setItem(this.SESSION_KEY_REDIRECT_URL, redirectUrl);
   }


   // ---------------------------------------------------------- Event handling

   public get isAuthenticatedChanged(): Observable<boolean> {
      return this.isAuthenticatedSubject;
   }

   public get isLoggingInChanged(): Observable<void> {
      return this.isLoggingInSubject;
   }


   // -------------------------------------------------------------- Properties

   public get isAuthenticated(): boolean {
      return this.provider.isSessionAuthenticated;
   }


   // ---------------------------------------------------------- Login / logout

   /**
    * Prompts the user to enter the credentials in order to login.
    */
   public async loginAsync(providerType: EProviderType, redirectUrl?: string): Promise<void> {
      if (!providerType) { providerType = EProviderType.Anonymous; }

      // Save redirect url
      this.setRedirectUrl(redirectUrl || window.location.pathname);

      // Login
      this.isLoggingInSubject.next();
      await this.provider.loginAsync();
      this.providerType = providerType;
      sessionStorage.setItem(this.SESSION_KEY_PROVIDER_TYPE, EProviderType[this.providerType]);
   }

   public loginAfterRedirectAsync(): Promise<boolean> {
      return this.provider.loginAfterRedirectAsync();
   }

   public loginFromUrlParameters(): boolean {
      return this.provider.loginFromUrlParameters();
   }

   public loginSSOAsync(promptOnInterationRequired: boolean): Promise<boolean> {
      return this.provider.loginSSOAsync(promptOnInterationRequired);
   }

   public confirmLoginAsync(): Promise<boolean> {
      return this.provider.confirmLoginAsync();
   }

   /**
    * Logs the user out and closes the session.
    */
   public async logoutAsync(): Promise<void> {
      if (this.providerType === undefined) { return; }

      this.providerType = undefined;
      sessionStorage.removeItem(this.SESSION_KEY_PROVIDER_TYPE);

      if (this.provider) {
         await this.provider.logoutAsync();
      }
   }


   // --------------------------------------------------- Access token handling

   public getApiAccessTokenAsync(): Promise<AccessToken> {
      return this.provider.getApiAccessTokenAsync();
   }


   // --------------------------------------------------- User profile handling

   /**
    * Logs the user out and closes the session.
    */
   public get user(): User | undefined {
      return this.provider.getUser();
   }

   /**
    * Sets the authentication provider and subscribes to its notifications.
    */
   private async setAuthenticationProviderAsync(providerType: EProviderType): Promise<void> {
      // Release current subscriptions
      this.subscription.unsubscribe();

      switch (providerType) {
         case EProviderType.MSAL:
            this.provider = this.msalProviderService;
            break;

         default:
            this.provider = new AnonymousProviderService();
            break;
      }
      await this.provider.initializeAsync(this.clientConfiguration);

      // Subscribe to new provider
      this.subscription = new Subscription();
      this.subscription.add(
         this.provider.isAuthenticatedChanged.subscribe((isAuthenticated: boolean) => { this.isAuthenticatedSubject.next(isAuthenticated); })
      );
   }
}
