import { Observable, Subject } from 'rxjs';

import { User } from '../model/user';
import { IAuthenticationProvider } from './iauthentication-provider';
import { ClientConfiguration } from '../../model/client-configuraton';
import { AccessToken } from '../model/access-token';

/**
 * Microsoft Authentication Library (MSAL)
 */
export class AnonymousProviderService implements IAuthenticationProvider {

   private isAuthenticatedSubject = new Subject<boolean>();

   constructor() { }

   public async initializeAsync(clientConfiguration: ClientConfiguration): Promise<void> { }

   // -------------------------------------------------------------- Properties

   public get isApiAuthenticated(): boolean {
      return false;
   }

   public get isSessionAuthenticated(): boolean {
      return false;
   }

   public get isAuthenticatedChanged(): Observable<boolean> {
      return this.isAuthenticatedSubject;
   }


   // ---------------------------------------------------------- Login / logout

   public loginAsync(): Promise<void> { return Promise.resolve(); }

   public loginAfterRedirectAsync(): Promise<boolean> { return Promise.resolve(false); }

   public loginFromUrlParameters(): boolean { return false; }

   public loginSSOAsync(promptOnInterationRequired: boolean): Promise<boolean> { return Promise.resolve(false); }

   public confirmLoginAsync(): Promise<boolean> { return Promise.resolve(false); }

   public logoutAsync(): Promise<void> { return Promise.resolve(); }

   // --------------------------------------------------- User profile handling

   public getUser(): User | undefined {
      return undefined;
   }

   public getApiAccessTokenAsync(): Promise<AccessToken> { return Promise.resolve(new AccessToken()); }
}
