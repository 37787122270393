import { InjectionToken } from '@angular/core';
import { IAscModuleDefinition } from '../i-asc-module-definition';
import { IamClientModuleConfiguration } from './model/iam-client-module-configuration';


export const MODULE_CONFIGURATION = new InjectionToken<IamClientModuleConfiguration>('module configuration');

export const IAM_DEFINITION: IAscModuleDefinition = {
   moduleId: 'IAM',
   displayName: 'Elektronische Geschäftsidentitäten',
   moduleRoute: {
      path: 'iam',
      loadChildren: () => import('src/modules/iam/iam.module').then(m => m.IamModule)
   }
};

export const IAM_ROUTE_PATH = {
   FORM: 'form'
};